<template>
    <div class="w-full justify-between h-20 flex justify-between items-center px-4 py-2 relative">

        <div class="flex items-center">
            <slot name="menuIcon" class="hidden">
                <div @click="openMenu" class="bg-main mr-6 rounded-2xl w-12 h-12 flex-center text-white flex md:hidden xl:flex">
                    <i class="mdi mdi-menu text-3xl"></i>
                </div>
                <div class="hidden md:flex xl:hidden h-10 flex items-center">
                    <!-- <vue-weather api-key="<your-open-weather-map-api-key>" units="auto" language="es" hide-header text-color="white">
                        <slot name="title">Valencia</slot>
                    </vue-weather> -->
                    <div class="flex flex-col items-start leading-none">
                        <span class="text-main text-lg font-medium"><i class="mdi mdi-clock-outline mr-2"></i>{{hour}}</span>
                        <span class="">{{date}}</span>
                    </div>
                    
                </div> 
            </slot>
            <slot name="edemLogo">
                <div v-if="isRoute == 'ExecutiveSummary'" class="logo flex md:hidden xl:flex">
                    <img class="w-20" src="/img/logos/logo_edem.svg" />
                </div>
            </slot>
        </div>

        <div v-if="isRoute == 'Traffic'" @click="changeMap" class="bg-secondary rounded-2xl w-12 h-12 flex-center text-white">
            <i v-if="!isWorldMap" class="mdi mdi-earth text-2xl"></i>
            <!-- cambiar icono aqui -->
            <img v-else src="/img/spain.svg" class="w-1/2">
        </div>

        <slot name="searchIcon">
            <div v-if="isRoute == 'Courses' || isRoute == 'ExecutiveSummary' " @click="openSearch" class="bg-secondary rounded-2xl w-12 h-12 flex-center text-white">
                <i v-if="closeSearch" class="mdi mdi-close text-2xl"></i>
                <i v-else class="mdi mdi-magnify text-2xl"></i>
            </div>
        </slot>



        <!-- Menu tablet -->
        <div class="hidden md:flex xl:hidden fixed bg-module p-6 text-left flex-col top-0 left-0 w-2/5 h-screen z-20 lateral-shadow">
            <router-link :to="{ name: 'ExecutiveSummary'}">
                <img class="w-20" src="/img/logos/logo_edem.svg" />
            </router-link>
            
            <span class="text-fontSecondary mt-10">Áreas</span>
            <All  model="Area" :immediate="true" ref="areasRef" v-slot="{data:areas}">
                <div v-if="areas.data">
                    <div class="flex items-center ml-1"
                            v-for="(area, index) in areas.data"
                            @click="goToArea(area)"
                            :key="index">
                        <i class="mdi mdi-school-outline text-mainSecondary text-2xl mr-3"></i>
                        <span class="text-text">{{ area.name }}</span>
                    </div>
                </div>
            </All>

          <span class="text-fontSecondary mt-10">Web</span>
          <div @click="goToTraffic" class="flex items-center ml-1 mt-2">
            <i class="mdi mdi-laptop text-mainSecondary text-2xl mr-3"></i>
            <span class="text-text">Tráfico web</span>
          </div>

          <span class="text-fontSecondary mt-10">Android</span>
          <div @click="downloadAndroidApk" class="flex items-center ml-1 mt-2">
            <i class="mdi mdi-laptop text-mainSecondary text-2xl mr-3"></i>
            <span class="text-text">Descagar Apk</span>
          </div>
            
            <div class="mt-auto">
                <div v-if="me.data.is_admin" @click="goToHome" class="items-center flex">
                    <i class="mdi mdi-home text-xl text-mainSecondary leading-none mx-2"></i>
                    <span class="text-gray-600">Backoffice</span>
                </div>
                <logout class="text-text">
                    <template #icon>
                        <i class="mdi mdi-logout-variant text-mainSecondary text-xl leading-none mx-2"></i>
                    </template>
                </logout>
            </div>
        </div>

        <transition
            enter-active-class="transform transition-all duration-500 ease-in-out"
            leave-active-class="transform transition-all duration-500 ease-in-out"
            enter-class="-translate-x-full"
            enter-to-class="-translate-x-0"
            leave-class="-translate-x-0"
            leave-to-class="-translate-x-full"
        >
            <div v-if="showMenu" class="flex md:hidden xl:flex fixed bg-module p-6 text-left flex-col top-0 left-0 w-4/5 h-screen z-20">
                <div class="">
                    <img class="w-20" src="/img/logos/logo_edem.svg" />
                </div>
                
                <span class="text-fontSecondary mt-10">Áreas</span>
                <All  model="Area" :immediate="true" ref="areasRef" v-slot="{data:areas}">
                    <div v-if="areas.data">
                        <div class="flex items-center ml-1"
                             v-for="(area, index) in areas.data"
                             @click="goToArea(area)"
                             :key="index">
                            <i class="mdi mdi-school-outline text-mainSecondary text-2xl mr-3"></i>
                            <span class="text-text">{{ area.name }}</span>
                        </div>
                    </div>
                </All>

              <span class="text-fontSecondary mt-10">Web</span>
              <div @click="goToTraffic" class="flex items-center ml-1 mt-2">
                <i class="mdi mdi-laptop text-mainSecondary text-2xl mr-3"></i>
                <span class="text-text">Tráfico web</span>
              </div>

              <span class="text-fontSecondary mt-10">Android</span>
              <div @click="downloadAndroidApk" class="flex items-center ml-1 mt-2">
                <i class="mdi mdi-android text-mainSecondary text-2xl mr-3"></i>
                <span class="text-text">Descagar Apk</span>
              </div>
                
                <div class="mt-auto">
                    <router-link v-if="me.data.is_admin" :to="{ name: 'Backoffice'}">
                        <div class="items-center flex">
                            <i class="mdi mdi-home text-xl text-mainSecondary leading-none mx-2"></i>
                            <span class="text-gray-600">Backoffice</span>
                        </div>
                    </router-link>

                    <logout class="text-text">
                        <template #icon>
                            <i class="mdi mdi-logout-variant text-mainSecondary text-xl leading-none mx-2"></i>
                        </template>
                    </logout>
                </div>
            </div>
        </transition>
        <transition
            enter-active-class="transform transition-all duration-1000 ease-in-out"
            leave-active-class="transform transition-all duration-1000 ease-in-out"
            enter-class="translate-x-full"
            enter-to-class="translate-x-0"
            leave-class="translate-x-0"
            leave-to-class="translate-x-full"
        >
            <div v-if="showMenu" @click="openMenu" class="fixed blurred top-0 pt-3 right-0 w-1/5 h-screen z-20">
                <div class="bg-main rounded-2xl w-12 h-12 mx-auto flex-center text-white">
                    <i class="mdi mdi-close text-3xl"></i>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { state, actions } from '@/store';
import logout from "@/pages/inside/shared/logout/Logout"
import All from "../../../../api/components/All";
// import VueWeather from "vue-weather-widget";
export default {
    name: 'Header',
    components: {
        All,
        logout,
        // VueWeather
    },
    props: ['closeSearch'],
    data() {
        return {
            showMenu: false,
            isWorldMap: false,
            hour: '',
            date: '',
        }
    },
    computed: {
        me() {
            return actions.getUser();
        },
        section() {
            return state.section
        },
        isRoute() {
            return this.$route.name
        }
    },
    methods: {
        openMenu() {
            this.showMenu = !this.showMenu
        },

        changeMap() {
            this.$emit('changeMap')
            this.isWorldMap = !this.isWorldMap
        },

        goToHome() {
            this.$router.push('/backoffice/courses');
            // this.openMenu()
        },

        goToTraffic() {
          this.$router.push('/traffic')
          // this.openMenu()
        },

        downloadAndroidApk() {
          const link = document.createElement("a");
          link.href = 'edem.apk';
          link.download = 'edem.apk';
          link.click();
        },

        goToArea(area){
            if(this.$route.path != '/courses/' + area.id) {
                this.$router.push('/courses/' + area.id);
            }
        },
        openSearch() {
            this.$emit('openSearch')
        },
        time() {
            var self = this
            this.hour = this.$dayjs().format('HH:MM')
            this.date = this.$dayjs().format('DD.MM.YY')

            setInterval(self.time, 60000)
        },
    },
    mounted() {
        this.time();
    }
}
</script>
<style  scoped>
    .blurred {
        backdrop-filter: blur(8px)
    }
</style>