<template>
   <transition name="fade" mode="out-in">
        <div class="portrait-only h-screen w-full px-8 md:px-56 text-center bg-module justify-center items-center z-50 fixed inset-0">
            <div class="z-50 relative flex flex-col justify-center items-center w-full h-full">
                <img class="w-32 mb-10 opacity-75" src="/img/edem-pez-azul.png" />
                <div class="font-bold text-white text-4xl"><span class="mdi mdi-information-outline mr-4"></span>Info</div>
                <span class="mdi mdi-phone-rotate-landscape mt-6 text-6xl text-main transform -rotate-90"></span>
                <div class="text-main text-sm opacity-75">Si estás en una tablet,<br> debes colocar el dispositivo en posición vertical</div>
                <router-link v-if="me.data.is_admin" :to="{ name: 'Backoffice'}">
                    <div class="items-center flex border-2 border-gray-800 rounded-full px-4 py-1 my-6">
                        <i class="mdi mdi-home text-xl text-mainSecondary leading-none mr-3"></i>
                        <span class="text-gray-600">Ir al backoffice</span>
                    </div>
                </router-link>                
            </div>
        </div>
    </transition>
</template>

<script>
import { actions } from '@/store';
export default {
    computed: {
        me() {
            return actions.getUser();
        },
    },
}
</script>

<style>

</style>